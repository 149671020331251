import { map } from 'rxjs/operators';
import { User } from './auth.service';
import { BaseService } from './base.service';
import { Collaboration } from './collaborations.service';
import { FileResource } from './files.service';
import { Track } from './tracks.service';

export type Project = {
    [key: string]:
        | string
        | number
        | boolean
        | null
        | undefined
        | User
        | User[]
        | FileResource[]
        | Track[]
        | Collaboration[];
    id: number | string;
    userId: number;
    creationDate: string | null;
    shareToken: string | null;
    modificationDate: string | null;
    name: string;
    bpm: number;
    signature: string;
    artist: string;
    album: string | null;
    metronomeOn: boolean;
    metronomeVolume: number;
    showEnvelopesOn: boolean;
    masterVolume: number;
    duration: number;
    zoomedInDuration: number | null;
    rulerType: string;
    snapToGrid: boolean;
    loopOn: boolean;
    loopStartTime: number;
    loopDuration: number;
    notes: string;
    playOverlappingSounds: boolean;
    displayCommentsAutomatically: boolean;
    isHidingCollaboratorTracks: boolean;
    followPlayhead: boolean;
    isPlayerDownloadPublic: boolean;
    usedSpaceWavsAndVideos?: number | null;
    user?: User;
    collaborators?: User[];
    collaborations?: Collaboration[];
    files: FileResource[];
    tracks: Track[];
    versionOfProjectId: null | number;
};

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ProjectsService extends BaseService {
    create(newProject: Project) {
        return this.http
            .post<Project>(`${this.apiUrl}/projects`, newProject)
            .pipe(map((project) => this.mapWithSubResources(project)));
    }

    readAll(shouldIncludeVersions = false) {
        let url = `${this.apiUrl}/projects`;

        if (shouldIncludeVersions) url += '?should-include-versions';

        return this.http
            .get<Project[]>(url)
            .pipe(
                map((projects) =>
                    projects.map((p) => this.mapWithSubResources(p))
                )
            );
    }

    read(projectId: number | string, usedSpaceWavsAndVideos = false) {
        let url = `${this.apiUrl}/projects/${projectId}`;

        if (usedSpaceWavsAndVideos) {
            url += '?used-space-wavs-and-videos';
        }

        return this.http
            .get<Project>(url)
            .pipe(map((project) => this.mapWithSubResources(project)));
    }

    readWithShareToken(token: string) {
        return this.http
            .get<Project>(`${this.apiUrl}/projects/share-token/${token}`)
            .pipe(map((project) => this.mapWithSubResources(project)));
    }

    update(project: Project) {
        project = this.cloneWithoutSubResources(project);

        return this.http.put<Project>(
            `${this.apiUrl}/projects/${project.id}`,
            project
        );
    }

    delete(id: number | string) {
        return this.http.delete(`${this.apiUrl}/projects/${id}`);
    }

    duplicate(id: number | string) {
        return this.http.patch(`${this.apiUrl}/projects/${id}/copy`, {});
    }

    unshare(id: number | string) {
        return this.http.delete(`${this.apiUrl}/projects/${id}/share`);
    }

    save(id: number | string, name: string) {
        return this.http.post(`${this.apiUrl}/projects/${id}/save`, { name });
    }

    load(id: number | string, projectIdToLoad: number) {
        return this.http.post(`${this.apiUrl}/projects/${id}/load`, {
            projectIdToLoad
        });
    }

    cloneWithoutSubResources<T>(project: T) {
        const subResourceKeys = [
            'files',
            'tracks',
            'user',
            'collaborators',
            'collaborations'
        ];

        return super.cloneWithoutSubResources(project, subResourceKeys);
    }

    private mapWithSubResources(project: Project) {
        project.tracks = [];
        project.files = [];

        return project;
    }
}
