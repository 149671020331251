import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Injectable, inject } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class BaseService {
    public apiUrl = environment.apiUrl;

    protected http = inject(HttpClient);

    public cloneWithoutSubResources<T>(
        resourceToClone: T,
        subResourceKeys: string[]
    ): T {
        const clone: T = {} as T;

        for (const key in resourceToClone) {
            if (!subResourceKeys.includes(key)) {
                clone[key] = resourceToClone[key];
            }
        }

        return clone;
    }
}
